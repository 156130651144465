import { Flex } from "rebass/styled-components";
import { Heading, ServiceIcon } from "../common";
import useTranslation from "next-translate/useTranslation";

import SearchNearbySelector from "./SearchNearbySelector";
import { Place } from "../../interfaces";

interface Props {
  countryServiceTypes: any[];
  selected: any;
  onSelect: (any) => void;
  onSelectSearchNearby: (place: Place) => void;
  sx?: any;
}

function ServiceSelector({
  selected,
  countryServiceTypes,
  onSelect,
  onSelectSearchNearby,
  sx
}: Props) {
  const { t } = useTranslation("common");
  return (
    <Flex
      sx={{
        flexDirection: "column",
        position: "absolute",
        top: "100%",
        width: "100%",
        px: 2,
        py: 3,
        mt: -1,
        zIndex: 1,
        bg: "background",
        borderLeft: "default",
        borderRight: "default",
        borderBottom: "default",
        borderBottomLeftRadius: "default",
        borderBottomRightRadius: "default",
        ...sx
      }}
    >
      <SearchNearbySelector onSelect={onSelectSearchNearby} />
      <Heading as="h4" variant="highlight" my={1}>
        {t("gudogServices")}
      </Heading>
      {countryServiceTypes.map(countryServiceType => {
        const isSelected = selected && countryServiceType.id == selected.id;
        return (
          <Flex
            key={countryServiceType.id}
            alignItems="center"
            my={1}
            sx={{
              color: "springGreen",
              fontWeight: isSelected ? "bold" : "normal",
              cursor: "pointer"
            }}
            onClick={() => onSelect(countryServiceType)}
          >
            <Flex
              mr={2}
              p={1}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                bg: isSelected ? "springGreen" : "background",
                border: "1px solid",
                borderColor: "springGreen",
                borderRadius: "24px",
                width: "64px",
                height: "64px",
                textAlign: "center",
                fontWeight: "book"
              }}
            >
              <ServiceIcon
                serviceTypeId={countryServiceType.serviceType.id}
                color={isSelected ? "background" : "springGreen"}
                fontSize={6}
              />
            </Flex>
            {t(`serviceType${countryServiceType.serviceType.id}`)}
          </Flex>
        );
      })}
    </Flex>
  );
}

export default ServiceSelector;
