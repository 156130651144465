import { Box, Text } from "rebass/styled-components";
import { Input, OutsideClickHandler, Button } from "../common";
import { useReducer } from "react";
import ServiceSelector from "./ServiceSelector";
import PlaceSelector from "./PlaceSelector";
import { useSearchDispatch, useSearchState } from "./SearchContext";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

interface Props {
  countryServiceTypes: any[];
  isMobileViewport: boolean;
  mobileMapVisible?: boolean;
  setMobileMapVisible?: (boolean) => void;
  label?: string;
  icon?: string;
  placeholder?: string;
}

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "selectCountryServiceType": {
      const { countryServiceType } = payload;
      return {
        ...state,
        selectedCountryServiceType: countryServiceType,
        showServiceSelector: false,
        showPlaceSelector: false
      };
    }
    case "selectPlace": {
      const { place } = payload;
      return {
        ...state,
        selectedPlace: place,
        showServiceSelector: false,
        showPlaceSelector: false
      };
    }
    case "inputChange": {
      const { inputValue } = payload;
      return {
        ...state,
        inputValue,
        showPlaceSelector: inputValue !== "",
        showServiceSelector: inputValue === ""
      };
    }
    case "inputFocus": {
      const { countryServiceTypesAvailable } = payload;
      return {
        ...state,
        showServiceSelector: countryServiceTypesAvailable && true,
        showPlaceSelector: !countryServiceTypesAvailable && true,
        inputValue: ""
      };
    }
    case "outsideClick":
      return { ...state, showServiceSelector: false, showPlaceSelector: false };
    default:
      throw new Error();
  }
};

function SearchBox({
  isMobileViewport,
  mobileMapVisible,
  countryServiceTypes,
  setMobileMapVisible,
  label,
  icon,
  placeholder
}: Props) {
  // Get shared state and dispatch from SearchContext
  const searchDispatch = useSearchDispatch();
  const searchState = useSearchState();

  // Reducer for internal state
  const [state, dispatch] = useReducer(reducer, {
    inputValue: "",
    showServiceSelector: false,
    showPlaceSelector: false,
    servicesAvailable: !!countryServiceTypes.length,
    selectedPlace: searchState.place, // Selected place
    selectedCountryServiceType: searchState.countryServiceType // Selected countryServiceType
  });

  const {
    selectedCountryServiceType,
    selectedPlace,
    showPlaceSelector,
    showServiceSelector,
    servicesAvailable,
    inputValue
  } = state;

  const { t } = useTranslation("common");

  let inputDisplayValue = inputValue;

  if (!showPlaceSelector && !showServiceSelector) {
    if (selectedCountryServiceType && !selectedPlace) {
      inputDisplayValue = t(
        `serviceType${selectedCountryServiceType.serviceType.id}`
      );
    } else if (!selectedCountryServiceType && selectedPlace) {
      inputDisplayValue = selectedPlace.near;
    } else if (selectedCountryServiceType && selectedPlace) {
      inputDisplayValue = t("otherCities.serviceIn", {
        service: selectedCountryServiceType.name,
        city: selectedPlace.near
      });
    }
  }

  if (!placeholder) {
    placeholder = selectedCountryServiceType
      ? t(`serviceType${selectedCountryServiceType.serviceType.id}In`, {
          city: selectedPlace?.near || ""
        })
      : t("serviceTypeDefaultIn");
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => dispatch({ type: "outsideClick" })}
      disabled={!showPlaceSelector && !showServiceSelector}
    >
      <Box sx={{ position: "relative" }}>
        {!!label && (
          <Text
            as="label"
            htmlFor="search-box"
            sx={{ display: "block", marginBottom: 1 }}
          >
            <Trans i18nKey={label} />
          </Text>
        )}
        <Input
          id="search-box"
          type="text"
          icon={icon}
          placeholder={placeholder}
          sx={{
            zIndex: 2,
            position: [
              "relative",
              showServiceSelector || showPlaceSelector ? "relative" : "static"
            ]
          }}
          value={inputDisplayValue}
          onFocus={() =>
            dispatch({
              type: "inputFocus",
              payload: {
                countryServiceTypesAvailable: servicesAvailable
              }
            })
          }
          onChange={event =>
            dispatch({
              type: "inputChange",
              payload: { inputValue: event.target.value }
            })
          }
          action={
            isMobileViewport && !mobileMapVisible ? (
              <Button onClick={() => setMobileMapVisible(true)}>
                {t("map")}
              </Button>
            ) : null
          }
        />
        {showServiceSelector && countryServiceTypes.length > 0 && (
          <ServiceSelector
            countryServiceTypes={countryServiceTypes}
            selected={selectedCountryServiceType}
            onSelect={countryServiceType => {
              searchDispatch({
                type: "changeCountryServiceType",
                payload: { countryServiceType }
              });
              dispatch({
                type: "selectCountryServiceType",
                payload: { countryServiceType }
              });
            }}
            onSelectSearchNearby={place => {
              searchDispatch({
                type: "changePlace",
                payload: {
                  place
                }
              });
              dispatch({
                type: "selectPlace",
                payload: { place }
              });
            }}
          />
        )}
        {showPlaceSelector && (
          <PlaceSelector
            input={inputValue}
            showSearchNearby
            onSelect={place => {
              searchDispatch({
                type: "changePlace",
                payload: {
                  place
                }
              });
              dispatch({
                type: "selectPlace",
                payload: { place }
              });
            }}
          />
        )}
      </Box>
    </OutsideClickHandler>
  );
}

SearchBox.defaultProps = {
  isMobileViewport: false,
  countryServiceTypes: [],
  icon: "search"
};

export default SearchBox;
