import { Box, Flex, Text } from "rebass/styled-components";
import { OutsideClickHandler, Button, Modal, Input, Icon } from "../common";
import { useReducer, useContext, useEffect, useRef } from "react";
import { MultipleDatesPicker, DateRangePicker } from "../datepicker";
import { format } from "date-fns";
import { useSearchDispatch, useSearchState } from "./SearchContext";
import useTranslation from "next-translate/useTranslation";

import { DateRange, MultipleDates, DatesFilterValue } from "../../interfaces";
import { DatesFilterMode } from "../../enums";
import {
  getDateFilterModeForCountryServiceType,
  getDateFnsLocale
} from "../../lib/utils";
import { LocaleContext } from "../locale";

interface State {
  visible: boolean;
  value: DatesFilterValue;
}

interface Props {
  onChange?: (value: DatesFilterValue) => void;
  isMobileViewport: boolean;
  renderInputs?: boolean;
}

interface Action {
  type: string;
  payload?: any;
}

interface DateInputProps {
  id: string;
  pushRight?: string;
}

interface RangeDateInputProps {
  start?: string;
  end?: string;
}

interface DatePickerWrapperProps {
  isMobileViewport: boolean;
  children: any;
  showResults: () => void;
  clear: () => void;
}

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case "buttonClick":
    case "inputClick":
      return { ...state, visible: true };
    case "outsideClick":
      return { ...state, visible: false };
    case "clear":
      return { ...state, visible: false, value: undefined };
    case "multipleDatesChange":
      return { ...state, value: { kind: "multipleDates", ...payload } };
    case "dateRangeChange":
      return { ...state, value: { kind: "dateRange", ...payload } };
    default:
      throw new Error();
  }
};

const DatePickerWrapper = ({
  isMobileViewport,
  children,
  showResults,
  clear
}: DatePickerWrapperProps) => {
  const { t } = useTranslation("common");

  return isMobileViewport ? (
    <Modal
      open
      size="full"
      onClose={() => showResults()}
      FooterButton={<Button onClick={() => showResults()}>{t("apply")}</Button>}
      HeaderButton={
        <Button variant="link" onClick={() => clear()}>
          {t("clear")}
        </Button>
      }
    >
      {children}
    </Modal>
  ) : (
    children
  );
};

function DatesFilter(props: Props) {
  // Get shared state and dispatch from SearchContext
  const searchDispatch = useSearchDispatch();
  const searchState = useSearchState();
  const { locale } = useContext(LocaleContext);
  const ref = useRef<HTMLElement>();

  // Reducer for internal state
  const [state, dispatch] = useReducer(reducer, {
    visible: false,
    value: searchState.datesFilterValue
  });

  const { value, visible }: State = state;
  const { t } = useTranslation("common");

  const applyDates = () => {
    // Dispatch the change to the Search context
    searchDispatch({
      type: "changeDatesFilterValue",
      payload: {
        datesFilterValue: value
      }
    });
  };

  const handleOutsideClick = () => {
    dispatch({ type: "outsideClick" });
    applyDates();
  };

  const handleClear = () => {
    dispatch({ type: "clear" });
    searchDispatch({
      type: "changeDatesFilterValue",
      payload: {
        datesFilterValue: undefined
      }
    });
  };

  const mode =
    props.renderInputs && !searchState.countryServiceType
      ? DatesFilterMode.DATE_RANGE
      : getDateFilterModeForCountryServiceType(searchState.countryServiceType);

  const renderDatePicker = () => {
    const past = {
      before: new Date()
    };
    switch (mode) {
      case DatesFilterMode.MULTIPLE_DATES:
        return (
          <MultipleDatesPicker
            value={value && (value as MultipleDates).dates}
            onChange={dates =>
              dispatch({ type: "multipleDatesChange", payload: { dates } })
            }
            numberOfMonths={props.isMobileViewport ? 12 : 2}
            canChangeMonth={props.isMobileViewport ? false : true}
            disabledDays={past}
            fromMonth={new Date()}
          />
        );
      case DatesFilterMode.DATE_RANGE:
        var dateRange = value as DateRange;
        return (
          <DateRangePicker
            from={dateRange && dateRange.startDate}
            to={dateRange && dateRange.endDate}
            onChange={dates =>
              dispatch({
                type: "dateRangeChange",
                payload: { startDate: dates.from, endDate: dates.to }
              })
            }
            numberOfMonths={props.isMobileViewport ? 12 : 2}
            canChangeMonth={props.isMobileViewport ? false : true}
            disabledDays={past}
            fromMonth={new Date()}
          />
        );
      default:
        throw new Error();
    }
  };

  const renderActions = () => {
    if (props.isMobileViewport) {
      return null;
    }

    return (
      <Flex justifyContent="space-between" px={2} py={0}>
        <Button
          variant="link"
          sx={{
            bg: "transparent",
            color: "labradorBlack",
            fontWeight: "book",
            pt: 0
          }}
          size="small"
          onClick={handleClear}
        >
          {t("clear")}
        </Button>
        <Button
          variant="link"
          sx={{
            bg: "transparent",
            color: "inuOrange",
            fontWeight: "book",
            pt: 0
          }}
          size="small"
          onClick={handleOutsideClick}
        >
          {t("apply")}
        </Button>
      </Flex>
    );
  };

  const renderButton = () => {
    return (
      <Button
        variant={visible || value ? "secondary" : "terciary"}
        size="medium"
        onClick={() => dispatch({ type: "buttonClick" })}
      >
        {(() => {
          if (!value) {
            return t("dates");
          }
          switch (mode) {
            case DatesFilterMode.MULTIPLE_DATES: {
              const { dates } = value as MultipleDates;
              return dates && dates.length
                ? t("dayWithCount", { count: dates.length })
                : t("dates");
            }
            case DatesFilterMode.DATE_RANGE: {
              const { startDate, endDate } = value as DateRange;
              return startDate && endDate
                ? `${format(startDate, "d MMM", {
                    locale: getDateFnsLocale(locale)
                  })} -  ${format(endDate, "d MMM", {
                    locale: getDateFnsLocale(locale)
                  })}`
                : t("dates");
            }
          }
        })()}
      </Button>
    );
  };

  const DateInput = props => (
    <Box flexBasis="100%">
      <Text
        as="label"
        htmlFor={props.id}
        sx={{
          display: "block",
          marginBottom: 1,
          marginLeft: props.pushRight ? 4 : 0
        }}
      >
        {t(props.id)}
      </Text>
      <Input
        type="text"
        placeholder={t("dateformat")}
        readOnly={true}
        bg="transparent"
        sx={{
          cursor: "pointer",
          width: "100%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "+i": { zIndex: 0 }
        }}
        onClick={() => {
          dispatch({ type: "buttonClick" });
        }}
        {...props}
      />
    </Box>
  );

  const RangeDateInput = ({ start, end }: RangeDateInputProps) => (
    <>
      <DateInput
        id="start-date"
        icon="check-in"
        sx={{
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          borderRight: 0,
          borderColor: visible ? "springGreen" : "gray",
          ":focus": { borderColor: "gray", outline: "none" },
          "+i": { zIndex: 0 }
        }}
        value={start}
      />
      <Box
        as="i"
        sx={{
          position: "absolute",
          fontSize: 1,
          left: "50%",
          bottom: 1,
          transform: "translate3d(-50%, -15%, 0)",
          zIndex: 0
        }}
      >
        <Icon as="i" name="next" />
      </Box>
      <DateInput
        id="end-date"
        icon="check-out"
        pushRight={true}
        sx={{
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          borderLeft: 0,
          paddingLeft: 7,
          borderColor: visible ? "springGreen" : "gray",
          "+i": { paddingLeft: 2, zIndex: 0 },
          ":focus": { borderColor: "gray", outline: "none" }
        }}
        value={end}
      />
    </>
  );

  const renderInputs = () => {
    switch (mode) {
      case DatesFilterMode.MULTIPLE_DATES: {
        if (!value) {
          return (
            <>
              <DateInput
                id="dates"
                icon="check-in"
                sx={{ borderColor: visible ? "springGreen" : "gray" }}
              />
            </>
          );
        } else {
          const { dates } = value as MultipleDates;
          return (
            <>
              <DateInput
                id="dates"
                icon="check-in"
                sx={{ borderColor: visible ? "springGreen" : "gray" }}
                value={
                  dates &&
                  dates.map(value => format(value, "dd/MM/yyyy")).join(", ")
                }
              />
            </>
          );
        }
      }
      case DatesFilterMode.DATE_RANGE: {
        if (!value) {
          return <RangeDateInput />;
        } else {
          const { startDate, endDate } = value as DateRange;
          return (
            <RangeDateInput
              start={startDate && format(startDate, "dd/MM/yyyy")}
              end={endDate && format(endDate, "dd/MM/yyyy")}
            />
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!ref.current && !visible) return;
    var bounding = ref.current.getBoundingClientRect();
    const bottomOffset =
      bounding.bottom -
      (window.innerHeight || document.documentElement.clientHeight);

    if (!props.isMobileViewport && bottomOffset > 0) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [visible, ref, props.isMobileViewport]);

  return (
    <Box
      width={props.renderInputs ? 1 : null}
      my={2}
      sx={{
        position: "relative",
        display: "inline-block"
      }}
    >
      <OutsideClickHandler
        disabled={!visible || props.isMobileViewport}
        onOutsideClick={handleOutsideClick}
      >
        <Flex sx={{ position: "relative" }}>
          {!props.renderInputs ? renderButton() : renderInputs()}
        </Flex>
        {visible && (
          <DatePickerWrapper
            isMobileViewport={props.isMobileViewport}
            showResults={handleOutsideClick}
            clear={handleClear}
          >
            <Box
              ref={ref}
              ml="auto"
              mr="auto"
              className={props.isMobileViewport ? "" : "desktop"}
              sx={{
                bg: "background",
                width: "100%",
                "&.desktop": {
                  zIndex: 2,
                  position: "absolute",
                  left: 0,
                  top: 6,
                  marginTop: props.renderInputs ? 4 : 0,
                  paddingBottom: 4,
                  border: "default",
                  width: "49em",
                  boxShadow: "inputFocus",
                  ".DayPicker-wrapper": {
                    padding: "2em 2em !important"
                  }
                }
              }}
            >
              {renderDatePicker()}
              {renderActions()}
            </Box>
          </DatePickerWrapper>
        )}
      </OutsideClickHandler>
    </Box>
  );
}

DatesFilter.defaultProps = {
  renderInputs: false
};

export default DatesFilter;
